@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  background-color: #fcfcfc;
  font-family: "GeorgiaRegular";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  @font-face {
    font-family: "GeorgiaBold";
    font-weight: 600;
    src: local("GeorgiaBold"),
      url(../src/assets/fonts/GeorgiaBold.ttf) format("truetype");
  }
  @font-face {
    font-family: "GeorgiaRegular";
    font-weight: 400;
    src: local("GeorgiaRegular"),
      url(../src/assets/fonts/GeorgiaRegular.ttf) format("truetype");
  }
  @font-face {
    font-family: "GraphikBold";
    font-weight: 700;
    src: local("GraphikBold"),
      url(../src/assets/fonts/GraphikBold.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikLight";
    font-weight: 300;
    src: local("GraphikLight"),
      url(../src/assets/fonts/GraphikLight.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikMedium";
    font-weight: 500;
    src: local("GraphikMedium"),
      url(../src/assets/fonts/GraphikMedium.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikRegular";
    font-weight: 400;
    src: local("GraphikRegular"),
      url(../src/assets/fonts/GraphikRegular.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikSemibold";
    font-weight: 600;
    src: local("GraphikSemibold"),
      url(../src/assets/fonts/GraphikSemibold.otf) format("opentype");
  }
}

.btn-loading {
  animation-name: btn-loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes btn-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d8d9da;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.getConsultationCSS {
  -moz-box-shadow: 0 0 5px #fabc68;
  -webkit-box-shadow: 0 0 5px #fabc68;
  transition: padding 1000ms, font-size 20px;
  box-shadow: 0 0 10px #fabc68;
}
.getConsultationCSS:hover {
  padding: 27px;
  box-shadow: 0px 0px 50px #ceaa64, 0px 10px 10px -4px rgba(255, 203, 31, 0.36),
    inset 4px 4px 8px rgba(245, 213, 31, 0.36),
    inset -4px -4px 8px rgba(245, 213, 31, 0.36);
}

/* 
Font Weight	Numerical Weight
"Thin", "Hairline"	100
"Extra Light", "Ultra Light"	200
"Light", "Book"	300
"Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
"Medium", "Demi"	500
"Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
"Bold", "Bld"	700/Bold
"Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
"Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900 
*/

.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

.slide img {
  /* width: 100%; */
  height: 100%;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.content {
  position: absolute;
  top: 23rem;
  left: 5rem;
  opacity: 0;
  width: 50%;
  color: #fff;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.3);
  animation: slide-up 1s ease 0.5s;
  /* animation-delay: 1s; */
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 80%;
  }
}

.content > * {
  color: #fff;
  margin-bottom: 1rem;
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.arrow {
  border: 2px solid white;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  z-index: 999;
}
.arrow:hover {
  background-color: #fff;
  color: #777;
}

.next {
  top: 35%;
  right: 1.5rem;
}
.prev {
  top: 35%;
  left: 1.5rem;
}

.landTower-transparent-btn {
  border-radius: 4px;
  color: #eaa94b;
  border: 2px solid #eccea8;
}

.tfe-font-size {
  font-size: 8px;
}

.overlay-container {
  position: relative;
  background-color: rgba(245, 40, 145, 0.8);
  width: 50%;
}

.overlay-container::hover .image {
  opacity: 0.3;
}

.overlay-container::hover .middle {
  opacity: 1;
}

.overlay-image {
  opacity: 1;
  display: block;
  /* width: 100%;
  height: auto; */
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-color: rgba(245, 40, 145, 0.8);
}

.overlay-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(245, 40, 145, 0.8);
}

.overlay-text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  background-color: rgba(245, 40, 145, 0.8);
}
